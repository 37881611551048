import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import styled from 'styled-components'
import CardList from "../../../components/CardListing/NewCardList"
import { Anchor, Box, Grid, TextInput, Paragraph, TextArea, Button } from "grommet"
import HeaderSideBar from "../../../components/layout/HeaderSideBarContent"
import YTVideo from "../../../components/YoutubeVideo"
import querystring from "query-string"
import Layout from "../../../containers/layout"
import Image from "../../../components/Image"
import Ad from "../../../containers/Ad"
import Parse from '../../../../vendor/b4a'

const ImageContainer = styled.div`
  cursor: pointer;
`

const Component = ({ data, location }) => {
  const qs = querystring.parse(location.search)
  return (

    <Layout max="100%">
          <Paragraph>
      Please Key in if you know the channel id
    </Paragraph>
      <TextInput 
        placeholder="channel id" 
        value={ qs && qs.title }
      />

      <Paragraph>
      Please Key in if you know the channel id
    </Paragraph>
      <TextArea>
        { qs && qs.message }
      </TextArea>
      <br />
      <Button label="Submit"></Button>
    </Layout>
  )
}

export default Component

