import React from "react"
import { Grid, Box, ResponsiveContext } from "grommet"

const Component = ({ children }) => {
  const size = React.useContext(ResponsiveContext)
  const rows = size === "small" ? ["auto", "auto", "auto"] : ["auto", "auto"]
  const areas =
    size === "small"
      ? [
          { name: "header", start: [0, 0], end: [1, 0] },
          { name: "main", start: [0, 1], end: [1, 1] },
          { name: "sidebar", start: [0, 2], end: [1, 2] },
        ]
      : [
          { name: "header", start: [0, 0], end: [1, 0] },
          { name: "main", start: [0, 1], end: [0, 1] },
          { name: "sidebar", start: [1, 1], end: [1, 1] },
        ]

  return (
    <Box align="center">
      <Box width="90%">
        <Grid
          fill
          rows={rows}
          columns={["flex", "flex"]}
          gap="small"
          areas={areas}
        >
          {children}
        </Grid>
      </Box>
    </Box>
  )
}

export default Component
